import React from "react"

export default function NotFoundPage()
{
  return(
    <>
      404 not found
    </>

  );
}